
* {
  font-family: "Be Vietnam", Arial, sans-serif !important;
  //color: #2c3e50;
  color: #212121;
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-x: hidden;
  width: 100vw;
}
#spectra {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #212121, #212121);
}

h1 {
  font-size: 2.6rem;
  line-height: 3.6rem;
}

h2 {
  font-size: 2rem;
}

.row {
  flex-direction: column;
}
.column {
  width: 100%;
}
p {
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 500;
}
.large {
  font-size: 1.2rem;
  line-height: 2rem;
}
label {
  font-size: 1.2rem;
  color: #868686;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

h2 span,
h2 span a,
h2 span a:hover,
p span,
p span a {
  color: white;
}

h3 span {
  color: white;
}

h3 span a {
  font-size: 1.5rem;

}

a:hover {
  color: #467AFF;
}

a.white {
  color: white;
  &:hover {
    color: #467AFF;
  }
}

p.secondary {
  margin-left: 0.5rem;
  opacity: 0.5;
}

.section {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
  padding: 10%;
}

.section.hero{
  height: auto;
}
.section.bio {
  min-height: auto;
  padding: 0% 10% 10%;
}

.section-background {
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center bottom;
  transform: translateX(-10%);
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.column {
  width: 100%;
}

.column-33 {
  flex: 0 0 calc(100% / 3 - 1rem);
}

.section-content {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.logo-container {
  height: 20vh;
  width: auto;
}

.blob-container {
  position: relative;
  top: -3em;
}

.align-center {
  display: flex;
  align-items: center;
  padding: 5% 0%;
}

.soundation {
  background-color: #191919;
  .section {
    padding: 5% 0%;
  }
  h2 {
    color: #E1E1E1;
    ;
  }
  label {
    color: grey;
  }
  p {
    color: #E1E1E1;
  }
}

.sampler {
    height: auto;
    img {
      max-width: 812px;
      height: auto;
    }
  }

/*
#kreditz-mockups {
  display: flex;
  justify-content: center;
}

.demo-video {
  position: absolute;
  margin-top: 22.9%;
  margin-left: 6.66%;
  width: 86.25%;
  background-color: transparent;
}
/*
.macbook {
  display: block;
  position: relative;
  background-image: url("macbook-pro.png");
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 100%;
  visibility: visible;
}*/
/*
#vntrs-iphone {
  visibility: hidden;
}

#vntrs-macbook {
  display: block;
  position: absolute;
  margin-left: 12.33%;
  width: 75.33%;
  margin-top: 3.33%;
  height: auto;
}*/
.tool-list {
  display: block;
  padding: 0;
  li {
    list-style: none;
    p {
      display: inline;
    }
  }
}

.tool-list img {
  margin-right: 1rem;
  height: 24px;
  width: auto;
  display: inline-block;
}

.scrolling-wrapper {
  width: 100vw;
  margin-left: -10vw;
  padding-bottom: 3rem;
  padding-right: 100px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

.scrolling-container {
  display: flex;
  height: 65vh;
  align-items: center;
  white-space: nowrap;
  vertical-align: middle;
  margin-right: auto;
  margin-left: auto;
  padding: 0 10vw;
  gap: 3rem;
  
}
.scrolling-container::-webkit-scrollbar {
  display: none;
}
.bouncing {
  font-family: "Permanent Marker", cursive !important;
}

.clip-wrapper {
  margin: 20px 0;
  position: relative;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.card {
  height: 100%;
  &:last-child{
    img{
      margin-right: 10vw;
    }
  }
}

.card img {
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.24);
  height: 100%;
  object-fit: contain;
}

.soundation {
  .card img {
    box-shadow: none;
  }
}

@media (min-width: 468px) {
  h1 {
    font-size: 9vw;
    line-height: 11vw;
  }
  h2 {
    font-size: 7vw;
    line-height: 9vw;
  }
  .section.hero {
    padding: 10% 10%;
    gap: 3rem;
  }
  .large {
    font-size: 3vw;
    line-height: 5vw;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 7vw;
    line-height: 9vw;
  }
  h2 {
    font-size: 5vw;
    line-height: 7vw;
  }
  h3 span a {
    font-size: 3vw;
  }
  p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    font-weight: 500;
  }
  
  .large {
    font-size: 3vw;
    line-height: 5vw;
    font-weight: 500;
  }
  
  label {
    color: #868686;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }
  
  .align-center {
    display: flex;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .column {
    width: calc(100% / 2 - 1rem);
  }
  .section-content {
    display: flex;
  }
  #guilloche {
    position: absolute;
    width: 500px;
    transform:translate(40vw, -700px);
    z-index: -999999;
  }
  .scrolling-container {
    height: 35rem;
  }
}
/*
@media (min-width: 1600px) {
  p {
    font-size: 1.5vw;
    line-height: 2.5vw;
  }
  label {
    font-size: 1.5vw;
  }
}
/*kreditz guilloche*/

#guilloche-container {
  width: 60%;
  position: absolute;
}

#guilloche {
  position: absolute;
  width: 500px;
  transform: translate(65vw, -700px);
  z-index: -999999;
  animation-play-state: running;
}

#blaj {
  position: absolute;
  animation: spin-right 250s linear infinite;
}
#blaj2 {
  position: absolute;
  animation: spin-left 250s linear infinite;
}

@keyframes spin-right {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}
@keyframes spin-left {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(-360deg);
  }
}

/*autoplay*/

.video{
    max-width: 600px;
    position: relative;
    &.playing{
      .play{
        opacity: 0;
      }
    }
  }
  
  .play{
    position: absolute;
    z-index: 100;
    width: 100%;

    display: flex;
    margin-top: 22.9%;
    margin-left: 6.66%;
    width: 86.25%; 
    height: 100%;
}

  video{
    position: relative;
    width: 100%;
  }

  /*Svg signature*/


  svg {
    transform: scale(1);
  }
  
  path {
    opacity: 0;
    stroke-dasharray: 2900;
  }
  .animate-path {
    opacity: 1;
    animation: drawSignature 5s forwards;
    animation-iteration-count: 1;
  }
  #Path_2 {
    stroke-dasharray: 2900;
  }
  .animate-path2 {
    animation: drawSignature 5s forwards;
    animation-iteration-count: 1;
  }

  #Path_3 {
    stroke-dasharray: 20;
  }
  .animate-path3 {
    opacity: 1;
    animation: drawDots1 5s infinite;
    animation-iteration-count: 1;
  }

  #Path_4 {
    stroke-dasharray: 20;
  }
  .animate-path4 {
    opacity: 1;
    animation: drawDots2 5s infinite;
    animation-iteration-count: 1;
  }

  @keyframes drawSignature {
    0% {
      stroke-dashoffset: 2900;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes drawDots1 {
    0% {
      stroke-dashoffset: 20;
    }
    80% {
      stroke-dashoffset: 20;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes drawDots2 {
    0% {
      stroke-dashoffset: 20;
    }
    86% {
      stroke-dashoffset: 20;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
